<template>
  <div>
    <form-agency />
  </div>
</template>

<script>
import FormAgency from './FormAgency.vue'

export default {
  components: {
    FormAgency,
  },
}
</script>
